var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2.129.4"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { fullStoryIntegration } from '@sentry/fullstory'
import {
  FullStory,
  init as initFullStory,
  isInitialized as isFullStoryInitialized,
} from '@fullstory/browser'
import getConfig from '@/config'

const publicRuntimeConfig = getConfig().publicRuntimeConfig
const SENTRY_DSN = publicRuntimeConfig.sentry?.dsn

if (SENTRY_DSN) {
  const disableTracking =
    typeof window !== 'undefined' &&
    window['navigator'].userAgent &&
    /(medshr.net test runner|burpsuite|zaproxy|googlesecurityscanner)/i.test(
      window['navigator']['userAgent']
    )

  const wantFullStory = publicRuntimeConfig.fullStory?.orgId && !disableTracking

  if (wantFullStory && !isFullStoryInitialized()) {
    initFullStory(publicRuntimeConfig.fullStory)
  }

  const hostRegExp = new RegExp(
    'https://([a-z0-9_-]+.)' +
      publicRuntimeConfig.baseHostName.replace('.', '\\.')
  )
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://09dcbc3815504702aeac943bcb8d4946@o174700.ingest.sentry.io/1257543',
    environment: publicRuntimeConfig.env,

    integrations: [Sentry.browserTracingIntegration()].concat(
      wantFullStory
        ? [fullStoryIntegration('medshr', { client: FullStory })]
        : []
    ),

    // To set a uniform sample rate - set to 1 for testing
    // (should lower to something like 0.2 in production
    tracesSampleRate: publicRuntimeConfig.tracesSampleRate ?? 1.0,

    tracePropagationTargets: ['localhost', /^\//, hostRegExp],

    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps

    beforeSend(event, hint) {
      // filter out UnhandledRejection errors that have no information
      if (
        event !== undefined &&
        event.exception !== undefined &&
        event.exception.values !== undefined &&
        event.exception.values.length == 1
      ) {
        const e = event.exception.values[0]
        if (
          e.type === 'UnhandledRejection' &&
          e.value.startsWith('Non-Error promise rejection captured')
        ) {
          return null
        }
      }

      const ex = hint.originalException
      const e = ex.toString()
      if (e.indexOf('Request blocked by client, probably adblock') > -1) {
        // branch load failed
        return null
      }
      if (e.indexOf('Error in API: 0') > -1) {
        // branch internal error
        return null
      }
      if (e.indexOf('Request has been terminated') > -1) {
        return null
      }

      return e.indexOf('ReferenceError: $jscomp is not defined') > -1 &&
        typeof navigator !== 'undefined' &&
        /GoogleSecurityScanner/.test(navigator.userAgent)
        ? null
        : event
    },
  })
}
