'use client'
import { publicRuntimeConfig } from './runtimeConfig'

const getConfig = () =>
  typeof window === 'undefined'
    ? { publicRuntimeConfig }
    : typeof window['__MS_CONFIG__'] !== 'undefined'
    ? { publicRuntimeConfig: window['__MS_CONFIG__'] }
    : typeof window['__NEXT_DATA__'] !== 'undefined' &&
      typeof window['__NEXT_DATA__']['runtimeConfig'] !== 'undefined'
    ? window['__NEXT_DATA__']['runtimeConfig']
    : { publicRuntimeConfig }

export default getConfig
